import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Regulation() {
  return (
    <Container id="regulation" sx={{ py: { xs: 8, sm: 16 } }}>
        <Box sx={{ width: '100%' }}>
            <Typography component="h2" variant="h4" color="text.primary" sx={{textAlign: 'center', marginBottom: 2}}>
              Regulation
            </Typography>
            <Stack spacing={2}>
                <Item>
                    <Typography>
                     Aktonis LTD (Company Licence Number: BFX2024147) - as a provider of this online service, is a Comoros based company with branches in Cyprus and a fully regulated company, which was licensed by the Comoros Financial Markets Supervisory Authority. after a thorough audit process. Since then, we have been continuously supervised and audited annually by the responsible Financial Services Quality Assurance Association.
                    </Typography>
                    <br/>
                    <Typography>
                        Officially recognised and monitored. We meet the most stringent requirements for the quality and safety of our services.
                    </Typography>
                    <br/>
                    <Typography>
                        The company is owned by Aktonis LTD. 
                    </Typography>
                    <Typography>
                        Company registration number:HA00724761
                    </Typography>
                    <Typography>
                        Company Licence Number: BFX2024147
                    </Typography>
                    <Typography>
                        The domain name lunitiargroup.com is operated by Lunitiar Group.
                    </Typography>
                </Item>
            </Stack>
        </Box>
    </Container>
    
  );
}