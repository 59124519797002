import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  // {
  //   icon: <SettingsSuggestRoundedIcon />,
  //   title: 'Security',
  //   description:
  //     `Crypto exchanges offer access to a vast selection of cryptocurrencies, allowing you to diversify your investments beyond just Bitcoin and Ethereum. Whether you're looking for established coins or emerging tokens, a crypto exchange provides a platform to explore and trade them all.`,
  // },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Transparency',
    description:
      `We have made maintaining proper licenses and certifications our top priority. Our compliance with (Security Standards) helps ensure robust protection of your funds and personal information.  We also adhere to both KYC and AML regulatory compliance measures to prevent illegal activities such as money laundering and terrorist financing.`,
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Security and Trust',
    description:
      `Aktonis LTD (Company Licence Number: BFX2024147) - as a provider of this online service, is a Comoros based company with branches in Cyprus and a fully regulated company, which was licensed by the Comoros Financial Markets Supervisory Authority after a thorough audit process. Since then, we have been continuously supervised and audited annually by the responsible Financial Services Quality Assurance Association.`,
  },
  // {
  //   icon: <AutoFixHighRoundedIcon />,
  //   title: 'Liquidity and Speed',
  //   description:
  //     `High liquidity on crypto exchanges means that you can buy and sell assets quickly and at a fair price. This is especially important in the volatile crypto market, where prices can change rapidly. An exchange with good liquidity ensures your orders are executed swiftly, reducing slippage.`,
  // },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'User-centricity',
    description:
      `Our service strives to provide straightforward navigation presented through simple and clear steps to make your experience as smooth and intuitive as possible. Should you have any questions, please feel free to contact us. Our dedicated customer support team is here to assist you every step of the way.`,
  },
  // {
  //   icon: <QueryStatsRoundedIcon />,
  //   title: 'Advanced Trading Features',
  //   description:
  //     `For more experienced traders, crypto exchanges provide advanced tools such as margin trading, futures contracts, and staking. These features allow you to implement sophisticated trading strategies, potentially increasing your profits while managing risks effectively.`,
  // },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Why choose us
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Here are a few reasons why clients choose us
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
