import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';

function Terms() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link variant="outlined" color="text.secondary"  onClick={handleClickOpen}>
        Terms of Service
      </Link>
      <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
        <DialogTitle>Terms of Service</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" component="div">
          
          </Typography>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Terms;
