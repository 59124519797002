import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,Link, CircularProgress } from '@mui/material';


function RegistrationForm() {

  const [open, setOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    amount: '',
    fulladress: ''
  });
  const [emailError, setEmailError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
    // setLoading(false)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(!emailRegex.test(value));
    }
  };
  const isFormValid = () => {
    return Object.values(formData).every((value) => value.trim() !== '');
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailError || !formData.email) {
      alert('Please enter a valid email address.');
      return;
    }
    const scriptURL = 'https://script.google.com/macros/s/AKfycbwCTQ5u1vqXdHs9b0ZBfcwKvSNgvG7tgNR65C9SaLwobnJkxvqMt5TcB9JMcXBj-YpK/exec';
    // setLoading(true)
    fetch(scriptURL, {
      method: 'POST',
      body: new URLSearchParams(formData),
    })
    .then((response) => {
     
      handleClose();
      setTimeout(() => {
        setSuccessModalOpen(true);
      }, 3500);
    })
    .catch((error) => {
      alert('Error!', error.message);
      // setLoading(false)
    });
  };

  

  return (
    <div>
      <Button 
        variant="contained" 
        onClick={handleClickOpen}  
        color="primary"
        size="small"
        component="a"
        target="_blank"
      >
        Start Now
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{textAlign:'center'}}>Create your order</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Full Name"
            type="text"
            fullWidth
            variant="standard"
            value={formData.name}
            onChange={handleChange}
            sx={{padding:'10px 0px'}}
            required
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={formData.email}
            onChange={handleChange}
            sx={{padding:'10px 0px'}}
            required
            error={emailError}
            helperText={emailError ? "Please enter a valid email address." : ""}
          />
          <TextField
            margin="dense"
            name="fulladress"
            label="Your address"
            type="text"
            fullWidth
            variant="standard"
            id="standard-multiline-flexible"
            multiline
            maxRows={4}
            value={formData.fulladress}
            onChange={handleChange}
            sx={{padding:'10px 0px'}}
            required
          />
           <TextField
            margin="dense"
            name="amount"
            label="Amount"
            type="number"
            required
            fullWidth
            variant="standard"
            value={formData.amount}
            onChange={handleChange}
            sx={{
              '& input[type=number]': {
                '-moz-appearance': 'textfield',
                '&::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
              },
              padding:'10px 0px'
          }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" disabled={!isFormValid()}>Submit</Button>
        </DialogActions>
      </Dialog>
      {/* {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'absolute'}}>
          <CircularProgress size={24} />
        </div>
      )} */}
      <Dialog open={successModalOpen} onClose={handleSuccessModalClose} sx={{textAlign: 'center'}}>
        <DialogTitle>Successful</DialogTitle>
        <DialogContent>
          <Typography>Your request has been successfully sent!</Typography>
          <Typography>You will received the instructions to your email 
          <Link 
            href={`mailto:${formData.email}`} 
            variant="body2" 
            color="primary"
          >
            &nbsp; {formData.email}&nbsp;
            </Link> 
          in a few minutes</Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setFormData({name:'', email:'', fulladress:'', amount: ''});
              handleSuccessModalClose();
            }} 
            variant="contained" 
            color="primary" 
            sx={{margin:'0 auto', width: 100}}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RegistrationForm;
