import React from 'react';
import LandingPage from './templates/landing-page/LandingPage';

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;