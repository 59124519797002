import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';

function PrivacyPolicyDialog() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link variant="outlined" color="text.secondary"  onClick={handleClickOpen}>
        Privacy Policy
      </Link>
      <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent dividers>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PrivacyPolicyDialog;
