import * as React from 'react';
import { useState, useEffect } from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ExchangeBlock from './ExchangeBlock'
import RegistrationForm from './RegistrationForm';

export default function Hero() {
  const [currency, setCurrency] = useState('EUR');
  const [amount, setAmount] = useState('');
  const [bitcoinAmount, setBitcoinAmount] = useState('');

  useEffect(() => {
    if (amount) {
      // Fetch the latest BTC conversion rate
      fetch(`https://api.coindesk.com/v1/bpi/currentprice/${currency}.json`)
        .then((response) => response.json())
        .then((data) => {
          const rate = data.bpi[currency].rate_float;
          setBitcoinAmount((amount / rate).toFixed(8)); // Update the Bitcoin amount
        })
        .catch((error) => console.error('Error fetching Bitcoin rate:', error));
    } else {
      setBitcoinAmount('');
    }
  }, [amount, currency]);
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '80%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            {/* Your choose&nbsp; */}
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Lunitiar Group
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '70%' } }}
          >
            At Lunitiar Group we make it safe and simple for you to purchase Bitcoin directly with your local currency. 
          </Typography>
          <Stack sx={{ textAlign: 'center', margin: '15px auto'  }}>
            <RegistrationForm />
          </Stack>
          {/* <Typography
             textAlign="center"
             color="text.secondary"
             sx={{ alignSelf: 'center', width: { sm: '100%', md: '70%' } }}
          >
          Lunitiar Group is a dedicated Bitcoin exchange specializing in seamless fiat-to-bitcoin transactions. Our mission is to make crypto accessible for everyone by offering a secure user-friendly platform for purchasing Bitcoin. We believe in the power of Bitcoin and are committed to providing a service that meets the highest standards of security transparency and user support. 
          </Typography> */}
          <Stack
            direction={{ xs: 'column' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            
            {/* <ExchangeBlock/> */}
            {/* <Button variant="contained" color="primary">
              Start now
            </Button> */}
            {/* <RegistrationForm variant="contained" /> */}
          </Stack>
          {/* <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
        </Stack>
        {/* <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        /> */}
      </Container>
    </Box>
  );
}
